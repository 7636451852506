<template>
  <PageHeader
    image="header-9"
    withMobile
    :title="$lang('title')"
    :breadcrumbs="['Student life', '>', `Student Visa`]"
  />
  <!-- section1 -->
  <section class="py-8 bg-blue4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue2 mb-4">{{ $lang("section1.title") }}</h2>

          <h3 class="text-green1">
            {{ $lang("section1.subtitle") }}
          </h3>
          <p>
            {{ $lang("section1.text1") }}
            <a href="https://www.irishimmigration.ie/" target="_blank">
              https://www.irishimmigration.ie/
            </a>
          </p>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <ImageContainer fileName="student_visa_1" />
        </div>
      </div>
    </div>
  </section>
  <!-- section2 -->
  <section class="pt-8 pb-10 bg-green3">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h2 class="text-blue2 mb-4">
            {{ $lang("section2.title") }}
          </h2>
          <h5 class="fw-semibold">
            {{ $lang("section2.subtitle") }}
          </h5>
        </div>

        <div class="col-lg-6 mb-5 mb-lg-0">
          <CardImage
            fileName="card_header_4"
            tag="Less than 12 weeks / Tourist Visa"
            tagClass="fw-bold bg-white"
            class="card-blue1"
            bodyClass="text-left"
          >
            <h5 class="fw-bold text-green2">
              {{ $lang("section2.subtitle_left") }}
            </h5>

            <ul class="ml-4 mb-4">
              <li>{{ $lang("section2.list1_left") }}</li>
              <li>{{ $lang("section2.list2_left") }}</li>
              <li>{{ $lang("section2.list3_left") }}</li>
              <li>{{ $lang("section2.list4_left") }}</li>
              <li>{{ $lang("section2.list5_left") }}</li>
              <li>{{ $lang("section2.list6_left") }}</li>
            </ul>
            <p class="mb-0">{{ $lang("section2.text1") }}</p>
          </CardImage>
        </div>
        <div class="col-lg-6">
          <CardImage
            fileName="card_header_5"
            tag="More than 12 weeks / Student Visa"
            tagClass="fw-bold bg-green2"
            class="card-blue2"
            bodyClass="text-left"
          >
            <h5 class="fw-bold text-green2">
              {{ $lang("section2.subtitle_right") }}
            </h5>

            <ul class="ml-4 mb-0">
              <li>{{ $lang("section2.list1_right") }}</li>
              <li>{{ $lang("section2.list2_right") }}</li>
              <li>{{ $lang("section2.list3_right") }}</li>
              <li>{{ $lang("section2.list4_right") }}</li>
              <li>{{ $lang("section2.list5_right") }}</li>
              <li>{{ $lang("section2.list6_right") }}</li>
            </ul>
          </CardImage>
        </div>

        <div class="col-12 pt-5">
          <div class="card card-green4">
            <div class="card-body p-5">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <p class="fw-bold m-0">
                    {{ $lang("section2.text2") }}
                  </p>
                </div>
                <div class="col-lg-5">
                  <a
                    href="https://www.irishimmigration.ie/coming-to-study-in-ireland/"
                    target="_blank"
                    class="btn btn-blue-grad-1 btn-block text-center"
                  >
                    {{ $lang("section2.button") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section3 -->
  <section class="floatting-section">
    <div class="container">
      <div class="card card-blue3 text-center">
        <div class="card-header">
          <div class="d-grid grid-3-cols max-w-4 float-top-center">
            <ImageContainer fileName="staff_bubble_1" />
            <ImageContainer fileName="staff_bubble_4" />
            <ImageContainer fileName="staff_bubble_2" />
          </div>
        </div>
        <div class="card-body pt-6">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <h3 class="mb-4">
                <span class="text-white"> {{ $lang("section3.title1") }} </span>
                {{ $lang("section3.title2") }}
              </h3>
              <button class="btn btn-blue-grad-1 px-5" @click="goTo('contact_us')">
                {{ $lang("section3.button") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section4 -->
  <section class="py-10 bg-blue4">
    <div class="container">
      <div class="row align-items-center mb-6">
        <div class="col-lg-5 order-lg-1 offset-lg-1 mb-4 mb-lg-0">
          <h3 class="text-blue2 mb-4">{{ $lang("section4.title") }}</h3>
          <p>{{ $lang('section4.text1') }} <b> {{ $lang('section4.text2') }}</b> {{ $lang('section4.text3') }}</p>

          <p>{{ $lang('section4.text4') }} <b> {{ $lang('section4.text5') }}</b></p>
        </div>
        <div class="col-lg-6">
          <ImageContainer fileName="student_visa_2" />
        </div>
      </div>

      <CardIcon
        :icon="$lang('section4.card_icon')"
        :title="$lang('section4.card_title')"
        :body="$lang('section4.card_body')"
      />
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CardImage from "@/components/CardImage";
import CardIcon from "@/components/CardIcon";

export default {
  components: {
    PageHeader,
    CardImage,
    CardIcon,
  },
};
</script>

<style></style>
